import React from 'react';

const Banner = ({ refProps }) => {
    return (
        <>
            <div ref={refProps} id="carouselExampleFade" className="carousel slide carousel-fade banner" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://themes.zozothemes.com/tranzlogistics/wp-content/uploads/sites/14/revslider/home-default/slide-3.jpg" className="d-block w-100" style={{height: '70vh'}} alt="..." />
                        <div className="carousel-caption d-block">
                            <h1>Road Transport Express <span className='highlight'>delivery</span></h1>
                            <h6>We are committed to providing our customers with exceptional service while offering our employees</h6>
                            <button className='btn'>Explore Us</button>
                            <button className='btn borderBtn'>Our Services</button>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="https://themes.zozothemes.com/tranzlogistics/wp-content/uploads/sites/14/revslider/home-default/slide-2.jpg" className="d-block w-100" style={{height: '70vh'}} alt="..." />
                        <div className="carousel-caption d-block">
                            <h1>best Logistics service <span className='highlight'>Provider</span></h1>
                            <h6>We are committed to providing our customers with exceptional service while offering our employees</h6>
                            <button className='btn'>Explore Us</button>
                            <button className='btn borderBtn'>Our Services</button>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="https://themes.zozothemes.com/tranzlogistics/wp-content/uploads/sites/14/revslider/home-default/slide-1.jpg" className="d-block w-100" style={{height: '70vh'}} alt="..." />
                        <div className="carousel-caption d-block">
                            <h1>Road Transport Express <span className='highlight'>delivery</span></h1>
                            <h6>We are committed to providing our customers with exceptional service while offering our employees</h6>
                            <button className='btn'>Explore Us</button>
                            <button className='btn borderBtn'>Our Services</button>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleFade" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    {/* <span className="visually-hidden">Previous</span> */}
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleFade" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    {/* <span className="visually-hidden">Next</span> */}
                </button>
            </div>
        </>
    )
}

export default Banner;