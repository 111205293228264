import React from 'react';

import Logo from '../assets/images/logo.jpg';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-grid">
                    <img src={Logo} alt="" />
                    <div className="flex-center">
                        <div>
                            <h3 className="mb-5">Office</h3>
                            <div className="d-flex align-items-center mb-2">
                                <i className="las la-phone-volume mr-2"></i>
                                <p className="mb-0">+(86) 21 6308 0671</p>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <i className="las la-envelope-open-text mr-2"></i>
                                <p className="mb-0">info@gojaeger-group.com</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <i className="las la-map-marked mr-2"></i>
                                <p className="mb-0">Workshop 60 3F block A, East Sun industrial Centre, No 16 Shing Yip Street, KL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='line'></div>
        </div>
    )
}

export default Footer;
