import React from 'react';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Loader from './utils/Loader';
import Home from './pages/home/Home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    loader: Loader,
  },
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
