import React from 'react';

const Contact = ({ refProps }) => {
    return (
        <div className="contact" ref={refProps}>
            <div className="container">
                <h1 className='text-center'>Contact <span className='highlight'>Us</span></h1>
                <div className="row">
                    <div className="col-sm contactDiv">
                        <i className="las la-phone-volume"></i>
                        <h6>PHONE</h6>
                        <p className="text-center">For emergency contact or service complain, please feel free to call.</p>
                        <a href='tel:098456785678'>+(86) 21 6308 0671</a>
                    </div>
                    <div className="col-sm contactDiv">
                        <i className="las la-envelope-open-text"></i>
                        <h6>EMAIL</h6>
                        <p className="text-center">Please feel free to share your inquiries or comments to the following email id.</p>
                        <a href='mailto:info@gojaeger-group.com'>info@gojaeger-group.com</a>
                    </div>
                    <div className="col-sm contactDiv">
                        <i className="las la-map-marker"></i>
                        <h6>LOCATION</h6>
                        <p className="text-center">Workshop 60 3F block A, East Sun industrial Centre, No 16 Shing Yip Street, KL</p>
                        <a href='http://maps.google.com/maps?q=210+Louise+Ave,+Nashville,+TN+37203' target='_blank' rel="noreferrer">View on Baidu map</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
