import React, { useEffect, useState } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Services = ({ refProps }) => {
    const [ renderItem, setrenderItem ] = useState(4);
    useEffect(
        () => {
            SetRenderItems();
            window.addEventListener('resize', () => {
                SetRenderItems();
            })
        }, []
    );
    const SetRenderItems = () => {
        if (window.innerWidth <=992) setrenderItem(3);
        if (window.innerWidth <=768) setrenderItem(2);
        if (window.innerWidth <=550) setrenderItem(1);
    }
    return (
        <div className='services' ref={refProps}>
            <div className="container">
                <h1 className='text-center'><span className='highlight'>Our</span> Services</h1>
                <h6 className='text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad. Architecto beatae vitae dicta sunting explicabo quae ab illo inventore.</h6>
                <br />
                <OwlCarousel className='owl-theme' items={renderItem} margin={30} autoplay nav loop>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-shield-alt"
                            title="Air Frieght"
                            description="Our aim is to optimize and improve your supply chain so that we can give you the best service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-shipping-fast"
                            title="Road Transport"
                            description="Our aim is to optimize and improve your supply chain so that we can give you the best service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-search-location"
                            title="Custom Clearance"
                            description="We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-trophy"
                            title="Stevedoring"
                            description="We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-trophy"
                            title="Terminal Operations"
                            description="We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-trophy"
                            title="Sea Frieght"
                            description="We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service."
                        />
                    </div>
                    <div class='item'>
                        <ServiceCard 
                            iconName="la-trophy"
                            title="Fright Forwarding"
                            description="We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service."
                        />
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default Services;

const ServiceCard = ({ title, description, iconName }) => {
    return (
        <div className='service-card'>
            <i className={`las ${iconName}`}></i>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
    )
}