import React, { useRef } from 'react';
import $ from 'jquery';

const About = ({ refProps }) => {
    const menuTitle = useRef();
    const menuContent = useRef();
    const onMenuChange = (id) => {
        $(`.menuItem`).removeClass('active');
        $(`.menuItem${id}`).addClass('active');
        $.when(
            $('#content').fadeOut()
            ).done(() => {
            $('#content').fadeIn();
            if (id === 1) {
                menuTitle.current.innerHTML = "Land Transport";
                menuContent.current.innerHTML = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores.";
            }
            if (id === 2) {
                menuTitle.current.innerHTML = "Worldwide Transport";
                menuContent.current.innerHTML = "To make the tabs toggleable, add the data-toggle=zttribute to each link. Then add a .tab-pane class with a unique ID for every tab and wrap them inside a div element with class .tab-content.";
            }
            if (id === 3) {
                menuTitle.current.innerHTML = "Own Warehousing";
                menuContent.current.innerHTML = "Lorem ite to each link. Then add a .tab-pane class with a unique ID for every tab and wrap them inside a div element with class .tab-contentp exea...";
            }
        })
    }
    return (
        <div className='about' ref={refProps}>
            <div className='container'>
                <div className="row">
                    <div className="col-sm bg-light p-0">
                        <div className='tabManu'>
                            <div className='menuItem menuItem1 active' onClick={() => onMenuChange(1)}>
                                <i className='las la-shipping-fast'></i>
                                <p className='mb-0'>Land Transport</p>
                            </div>
                            <div className='menuItem menuItem2' onClick={() => onMenuChange(2)}>
                                <i className='las la-plane-departure'></i>
                                <p className='mb-0'>Worldwide Transport</p>
                            </div>
                            <div className='menuItem menuItem3' onClick={() => onMenuChange(3)}>
                                <i className='las la-warehouse'></i>
                                <p className='mb-0'>Own Warehousing</p>
                            </div>
                        </div>
                        <div className='menuContent'>
                            <div id='content'>
                                <h5 className='highlight mb-2' ref={menuTitle}>Land Transport</h5>
                                <span className='text-secondary' ref={menuContent}>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className='p-3'>
                            <h4 className='highlight'>ABOUT US</h4>
                            <h1>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</h1>
                            <p className='text-secondary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip exea...</p>
                            <button className='btn'>Read More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
