import React, { useState } from 'react';

import Logo from '../assets/images/logo.jpg';

const Navigation = ({ bannerRef, servicesRef, aboutRef, contactRef }) => {
    const [ modalShow, setModalShow ] = useState(false);
    
    const navigateToBanner = () => bannerRef.current.scrollIntoView();
    const navigateToServices = () => servicesRef.current.scrollIntoView();
    const navigateToAbout = () => aboutRef.current.scrollIntoView();
    const navigateToContact = () => contactRef.current.scrollIntoView();
    return (
        <>
            <Topbar />
            <div className='navigationBar'>
                {modalShow && <Modal navigateToBanner={navigateToBanner} navigateToServices={navigateToServices} navigateToAbout={navigateToAbout} navigateToContact={navigateToContact} setModalShow={setModalShow} />}
                <div className='container'>
                    <img src={Logo} alt="logo" className='topFixedLogo' />
                    {!modalShow && <button className='btn' onClick={() => setModalShow(true)}><i className="las la-bars"></i></button>}
                    <div className='row justify-content-end align-items-center'>
                        <div className='navigationItem flex-center' onClick={navigateToBanner}>Home</div>
                        <div className='navigationItem flex-center' onClick={navigateToServices}>Services</div>
                        <div className='navigationItem flex-center' onClick={navigateToAbout}>About</div>
                        <div className='navigationItem flex-center' onClick={navigateToContact}>Contact</div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Topbar = () => {
    const TopbarItem = ({ label, desc, icon }) => {
        return (
            <div className='flex-center text-white topbarItem'>
                <i className={icon}></i>
                <div>
                    <span>{label}</span>
                    <span>{desc}</span>
                </div>
            </div>
        )
    }
    return (
        <div className='topbar'>
            <div className='container'>
                <div className='row justify-content-end align-item-center'>
                    <TopbarItem 
                        label="One Canada Square" 
                        desc="Canary Wharf, United Kingdom."
                        icon="las la-map-marker"
                    />
                    <TopbarItem 
                        label="Monday-Friday: 9am to 5pm" 
                        desc="Saturday / Sunday: Closed"
                        icon="las la-clock"
                    />
                    <TopbarItem 
                        label="+(123) 456-7890" 
                        desc="info@example.com"
                        icon="las la-headset"
                    />
                </div>
            </div>
        </div>
    )
}
const Modal = ({navigateToBanner, navigateToServices, navigateToAbout, navigateToContact, setModalShow}) => {
    return (
        <div className='navigationModal' onClick={() => setModalShow(false)}>
            <button className='btn'><i className="las la-times"></i></button>
            <div>
                <div className='navigationItem flex-center' onClick={navigateToBanner}>Home</div>
                <div className='navigationItem flex-center' onClick={navigateToServices}>Services</div>
                <div className='navigationItem flex-center' onClick={navigateToAbout}>About</div>
                <div className='navigationItem flex-center' onClick={navigateToContact}>Contact</div>
            </div>
        </div>
    )
}

export default Navigation;
