import React, { useRef } from 'react';

import Navigation from '../../components/Navigation';
import Banner from '../../components/Banner';
import Services from '../../components/Services';
import About from '../../components/About';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';

const Home = () => {
    const bannerRef = useRef();
    const servicesRef = useRef();
    const aboutRef = useRef();
    const contactRef = useRef();
    return (
        <div>
            <Navigation 
                bannerRef={bannerRef}
                servicesRef={servicesRef}
                aboutRef={aboutRef}
                contactRef={contactRef}
            />
            <Banner refProps={bannerRef} />
            <Services refProps={servicesRef} />
            <About refProps={aboutRef} />
            <Contact refProps={contactRef} />
            <Footer />
        </div>
    )
}

export default Home;
